<template>
    <div class="reserve-details" :class="{'cancel-bottom':pageConfigObj.canCancel == 1&&details.isCanceled!=1}">
        <div class="detail-container">
            <p class="title">
                {{$t("reserve.reservebyInfo")}}
            </p>
            <div class="reserve-info">
                <div class="info-item" v-if="pageConfigObj.fieldName == 1">
                    <p class="label">{{$t("appointment.fullName")}}</p>
                    ：
                    <p class="value">
                        {{details.userName}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldTel == 1">
                    <p class="label">{{$t("appointment.phone")}}</p>
                    ：
                    <p class="value">
                        {{details.userTel}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldGender == 1">
                    <p class="label">{{$t("appointment.gender")}}</p>
                    ：
                    <p class="value">
                        {{details.userGender}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldIdCode == 1">
                    <p class="label">{{$t("appointment.IDcode")}}</p>
                    ：
                    <p class="value">
                       {{details.userIdCode}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldAddress == 1">
                    <p class="label">{{$t("appointment.address")}}</p>
                    ：
                    <p class="value">
                       {{details.userAddress}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldTitle == 1">
                    <p class="label">{{$t("appointment.positionalTitle")}}</p>
                    ：
                    <p class="value">
                       {{details.userTitle}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldDept == 1">
                    <p class="label">{{$t("appointment.department")}}</p>
                    ：
                    <p class="value">
                       {{details.userDept}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldDuty == 1">
                    <p class="label">{{$t("appointment.duties")}}</p>
                    ：
                    <p class="value">
                       {{details.userDuty}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldHosp == 1">
                    <p class="label">{{$t("appointment.hospital")}}</p>
                    ：
                    <p class="value">
                       {{details.userHosp}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldEducation == 1">
                    <p class="label">{{$t("appointment.education")}}</p>
                    <p class="value">
                       ：{{details.userEducation}}
                    </p>
                </div>
                <div class="info-item"  >
                    <p class="label">预约项目</p>
                    ：
                    <p class="value">
                       {{details.reserveProjectName}}
                    </p>
                </div>
                <div class="info-item" v-if="pageConfigObj.fieldImg == 1&&details.userImg">
                    <p class="label">{{$t("appointment.photo")}}</p>
                       ：
                    <p class="value">
                        <img :src="details.userImg"  @click="seeImg">
                    </p>
                </div>
                <img class="reserve-status" v-if="details.isCanceled == 1" src="@/assets/img/reserve-type-icon3.png" alt="">
                <img class="reserve-status" v-if="details.isCheckin == 1"  src="@/assets/img/reserve-type-icon2.png" alt="">
                <img class="reserve-status" v-if="details.isExpired == 1"  src="@/assets/img/reserve-type-icon1.png" alt="">
            </div>
            <div class="ewm-container">
                <div class="ewm-head" :class="details.isCanceled == 1?'status-head1':details.isCheckin==1?'status-head2':details.isExpired==1?'status-head3':''">
                    <p class="time">预约日期：{{details.reserveDate}}</p>
                    <p class="time-slot">
                        {{details.reserveTimeRangeStart}}-{{details.reserveTimeRangeEnd}}
                    </p>
                </div>
                <div class="erw">
                    <div class="erw-img" ref="qrcode"></div>
                    <p class="txt">
                        请凭此二维码核销
                    </p>
                </div>
            </div>
            <p class="address" v-if="details.reserveAddress">
                <span class="label">门店地址：</span>
                <span class="value">
                    {{details.reserveAddress}}
                </span>
            </p>
            
        </div>
        <div class="bottom-btn" v-if="pageConfigObj.canCancel == 1&&details.isCanceled==0&&details.isCheckin == 0">
            <p class="btn" @click="cancelReserve"  >{{$t("appointment.cancelReserve")}}</p>
        </div>
    </div>
</template>

<script>
import { reserveDetails,ReserveGetInfo, reserveCancel } from "@/api/appointment";
import { ImagePreview } from 'vant';
import QRCode from "qrcodejs2";

export default {
    data () {
        return {
            reserveUserId:this.$route.query.reserveUserId,
            reserveId:this.$route.query.reserveId,
            details:{},
            pageConfigObj:{},
            ewmObj:{"objType": 104042,"objId": this.$route.query.reserveUserId}
        }
    },
    async created () {
        document.title = this.$t("appointment.reserveDetail");
         const toast = this.$Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t("message.loadingTxt")
        });
        await this.getFormConfig();
        await this.getDetails()
        toast.clear()
    },
    mounted () {
        this.qrcodeRender()
    },
    methods: {
        getFormConfig(){
            
            ReserveGetInfo({
                reserveId:this.reserveId
            }).then(res =>{
                this.pageConfigObj = res.data.data;
            })
        },
        getDetails(){
            reserveDetails({
                reserveUserId:this.reserveUserId
            }).then(res =>{
                this.details = res.data.data;
            })
        },
        // 转换二维码
        qrcodeRender(id) {
            console.log(this);
            this.$refs.qrcode.innerHTML = "";
			//获取当前的ip和端口，不要写死
            this.qrCodeImg = null;
            this.qrCodeImg = new QRCode(this.$refs.qrcode, {
                width: 235,
                height: 235,
                text: JSON.stringify(this.ewmObj), // 二维码地址
                colorDark: "#000",
                colorLight: "#fff",
                correctLevel: QRCode.CorrectLevel.H,
            });
        },
        seeImg(){
            ImagePreview([this.details.userImg]);
        },
        cancelReserve(){
             const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            reserveCancel({
                reserveUserId:this.reserveUserId
            }).then(res =>{
                if(res.data.errorCode == 0){
                    this.$toast("预约已取消")
                    this.$router.go(-1)
                }else{
                    this.$toast(res.data.errorMsg)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.reserve-details{
     overflow: auto;
    height: 100%;
    box-sizing: border-box;
    background: #F5F6FA;
    position: relative;
    .detail-container{
        padding: 0.3rem 0.6rem 0.6rem;
        margin: 0.4rem 0;
        background: #fff;
        .title{
            font-size: 0.72rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #303133;
            line-height: 1.5rem;
            padding-left: 0.6rem;
            position: relative;
            &::after{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0,-50%);
                content: ' ';
                width: 0.16rem;
                height: 0.72rem;
                background: #3E8EFA;
                border-radius: 0.12rem;
            }
        }
        .reserve-info{
            position: relative;
            .info-item{
                font-size: 0.64rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.9rem;
                margin: 0.2rem 0;
                display: flex;
                .label{
                    flex: 0 0 auto;
                    width: 3rem;
                    text-align: justify;
                    text-align: justify;
                    text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
                    text-align-last: justify;
                    height: 0.6rem;
                    &::after{
                        content: '';
                        width: 100%;
                        height: 0;
                        display: inline-block;
                        visibility: hidden;
                    }
                }
                .value{
                    flex: 1;
                    img{
                        max-width: 2rem;
                        max-height: 2rem;
                        height: auto;
                        width: auto;
                    }
                }
            }
            .reserve-status{
                position: absolute;
                right: 0.6rem;
                top: 0;
                width: 3.4rem;
                height: 2.8rem;
            }
        }
        .ewm-container{
            width: 11.4rem;
            background: #FFFFFF;
            box-shadow: 0 0.08rem 0.2rem 0 rgba(185,185,185,0.5);
            border-radius: 0.4rem;
            margin: 0 auto;
            height: max-content;
            overflow: auto;
            margin-top: 0.6rem;
            .ewm-head{
                height: 3.56rem;
                background: linear-gradient(360deg, #3387FD 0%, #49C1FF 100%);
                border-radius: 0.4rem 0.4rem 0 0;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                .time{
                    font-size: 0.64rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 0.9rem;
                }
                .time-slot{
                    font-size: 1.08rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 1.5rem;
                    letter-spacing: 0.1rem;
                }
            }
            .status-head1{
                background: linear-gradient(360deg, #FF8C47 0%, #FCC7A8 100%);
            }
            .status-head2{
                background: linear-gradient(360deg, #F56C6C 0%, #FAB6B6 100%);
            }
            .status-head3{
                background: linear-gradient(360deg, #979797 0%, #B6B6B6 100%);
            }
            .erw{
                margin: 0.7rem auto;
                /deep/.erw-img{
                    height: 5.4rem;
                    width: 5.4rem;
                    margin: 0 auto;
                    img, canvas{
                        width: 100%;
                        height: 100%;
                    }
                    
                }
                img{
                    width: 100%;
                    height: 100%;
                }
                .txt{
                    margin-top: 0.3rem;
                    font-size: 0.52rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    text-align: center;
                    line-height: 0.74rem;
                }
            }
        }
        .address{
            margin: 0.7rem auto;
            max-width: 11.4rem;
            display: flex;
            font-size: 0.52rem;
            align-items: flex-start;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 0.74rem;
            width: max-content;
            .label{
                flex: 0 0 auto;
            }
            .value{
                flex: 1;
            }
        }
    }
    .reserve-status{

    }
    .bottom-btn{
        position: fixed;
        left: 0;
        bottom: 0;
        background: #FFFFFF;
        width: 100%;
        height: 3rem;
        box-shadow: 0 -0.08rem 0.3rem 0 rgba(199,199,199,0.5);
        border-radius: 0.06rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
            width: 11.2rem;
            height: 1.76rem;
            line-height: 1.76rem;
            border-radius: 1rem;
            text-align: center;
            font-size: 0.76rem;
        
            background: linear-gradient(180deg, #49BFFF 0%, #499FFF 100%);
            color: #fff;
        }
    }
}
.cancel-bottom{
    padding-bottom: 2.8rem;
}
</style>